<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="flex h-screen w-full bg-img vx-row no-gutter justify-center"
    id="page-login"
  >
    <div class="container">
      <div class="vx-col lg:w-1/2 sm:m-0">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col w-full d-theme-dark-bg border rounded">
                <div class="login-tabs-container border rounded">
                  <div class="vx-card__title">
                    <h1 align="center">Reset Password</h1>
                  </div>
                  <br />
                  <div>
                    <div class="input-wrap">
                      <vs-input
                        type="password"
                        label-placeholder="Password"
                        v-model="password"
                        class="w-full mt-9"
                      />
                    </div>
                    <div class="input-wrap">
                      <vs-input
                        type="password"
                        label-placeholder="Confirm Password"
                        v-model="confirmPassword"
                        class="w-full mt-9"
                      />
                    </div>
                    <div class="input-wrap">
                      <div
                        class="flex flex-wrap justify-between flex-col-reverse sm:flex-row"
                      >
                        <vs-button
                          class="flex flex-wrap justify-content-center my-2"
                          :disabled="!validateForm"
                          @click="setPassword"
                          >Reset</vs-button
                        >
                        <vs-button
                          type="border"
                          @click="setPassword"
                          :to="{ name: 'front-login' }"
                          class="flex flex-wrap justify-content-center mt-5 mb-3"
                          >Go Back To Login</vs-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
  <!-- <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Reset Password</h4>
                                    <p>Please enter your new password.</p>
                                </div>
                                <vs-input type="password" label-placeholder="Password" v-model="password" class="w-full mb-6" />
                                <vs-input type="password" label-placeholder="Confirm Password" v-model="confirmPassword" class="w-full mb-8" />

                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                                    <vs-button type="border" :to="{ name: 'admin-login'}" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Go Back To Login</vs-button>
                                    <vs-button class="w-full sm:w-auto" :disabled="!validateForm" @click="setPassword">Reset</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
  </div>-->
</template>

<script>
export default {
  data() {
    return {
      userDetails: {},
      password: "",
      confirmPassword: "",
      message: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.password != "" &&
        this.confirmPassword != "" &&
        this.password == this.confirmPassword
      );
    }
  },
  methods: {
    setPassword() {
      this.userDetails.password = this.password;
      this.userDetails.token = this.$route.params.token;
      //console.log(this.$route.params.token);
      //payload.userDetails.password
      let payload = this.userDetails;

      this.$validator.validate().then(valid => {
        if (valid) {
          this.$vs.loading();
          this.$store
            .dispatch("auth/resetPassword", payload)
            .then(response => {
              const user = response.data.data;

              this.$vs.notify({
                title: "Success",
                text: "Password reset Successfully",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });

              let userType = user.userType;

              if (userType == "learningCenter") {
                userType = user.role;
              }

              switch (userType) {
                case "superAdmin":
                case "admin":
                  this.$router.push({ path: "/admin/login" });
                  break;
                case "centreDirector":
                  this.$router.push({ path: "/login/" });
                  break;
                case "centerAdmin":
                  this.$router.push({ path: "/login/" });
                  break;
                case "parent":
                  this.$router.push({ path: "/login/" });
                  break;
                case "guardians":
                  this.$router.push({ path: "/" });
                  break;
                case "teacher":
                  this.$router.push({ path: "/login/" });
                  break;
                default:
                  this.$router.push({ path: "/" });
                  break;
              }
              this.$vs.loading.close();
            })
            .catch(error => {
              if (error.response.status === 410) {
                this.$vs.notify({
                  title: "Error",
                  text: "Expired/Invalid Token",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning"
                });
                this.$vs.loading.close();
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
#page-login {
  background: #ffffff;
  .vx-col {
    .w-full {
      width: 100% !important;
    }
    margin: 0 auto !important;
    width: 100%;
    @media (min-width: 992px) {
      width: 80%;
    }
    @media (min-width: 1200px) {
      width: 67% !important;
    }
    @media (min-width: 1480px) {
      width: 75% !important;
    }
    @media (min-width: 1640px) {
      width: 68% !important;
    }
  }

  .vx-card {
    border-radius: 8px !important;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.11);
    margin: 60px 0;
    overflow: hidden;
    @media (max-width: 767px) {
      box-shadow: none;
      margin: 30px 0;
      border-radius: 0;
    }
    @media (min-width: 1200px) {
      margin: 80px 0;
    }
    @media (min-width: 1640px) {
      margin: 90px 0;
    }
  }

  .vs-button {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    padding: 15px !important;
    width: 100%;
  }
}

.login-tabs-container {
  padding: 20px;
  width: 100%;
  @media (min-width: 992px) {
    width: 80%;
    margin: 20px auto !important;
  }
  @media (min-width: 1024px) {
    margin: 60px auto !important;
    width: 70%;
  }
  @media (min-width: 1200px) {
    margin: 70px auto !important;
    width: 67%;
  }
  @media (min-width: 1640px) {
    width: 56%;
    margin: 94px auto !important;
  }
  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (min-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 1640px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 26px;
    }
  }
}
</style>
